.dossier-top-bottom-border {
    border-bottom: 3px solid #000000 !important;
    border-top: 3px solid #000000 !important;
    width: auto;
    padding: 5px;
}

.dossier-full-border {
    border: 2px solid #000000 !important;
    width: auto;
    padding: 5px;
}

.dossier-table td {
    border-bottom: 1px solid #000000 !important;
    width: auto;
    padding: 5px;
}
.dossier-title {
    padding-right: 0;
}

.dossier-title-space {
    padding-left: 0;
    justify-content: center;
    align-items: center;
}

.dossier-table th {
    text-transform: uppercase;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px;
}

.dossier-hr {
    display: block;
    height: 1px;
    background: transparent;
    width: 100%;
    border: none;
    border-top: solid 1px #000000;
}